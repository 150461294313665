import {useState} from "react";
import axios from "../../httpService/axiosInterseptor"
import {AxiosError} from "axios"
import {useMessages} from "../../context/MessagesSnackbarContext";

export function useDeleteMany<T>(resource: string) {
    const [isLoading, setLoading] = useState(false)
    const { setError } = useMessages()

    async function fetch(dto: Array<T>) {
        try {
            setLoading(true)
            await axios.delete(resource, {data: dto});
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(true)
            setError(error.message)
            console.error(error.message)
        }
    }

    return { isLoading, fetch }
}