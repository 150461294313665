import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridSortModel,
    GridValueFormatterParams
} from '@mui/x-data-grid';
import {useGetMany} from "../../hooks/dataProvider/useGetMany";
import {config} from "../../data/Constants";
import {SortingDirection} from "../../data/sortDirection";
import {Alert, Button, Fab} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useDeleteMany} from "../../hooks/dataProvider/useDeleteMany";
import AddSocialCardDialog from "./AddSocialCardDialog";
import FilterButton from "../filtration/FilterButton";
import {IFilter} from "../filtration/IFilter";
import {ISocialCard} from "./ISocialCard";
import {useMessages} from "../../context/MessagesSnackbarContext";
import {GridPaginationModel} from "@mui/x-data-grid/models/gridPaginationProps";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'UID', flex: 1, minWidth: 100, maxWidth: 250, type: 'string', editable: false, sortable: false },
    { field: 'firstName', headerName: 'Ім`я', flex: 1, minWidth: 80, maxWidth: 150, type: 'string', editable: false, },
    { field: 'lastName', headerName: 'Фамілія', flex: 1, minWidth: 80, maxWidth: 150, type: 'string', editable: false, },
    { field: 'data', headerName: 'Додаткові данні', flex: 1, minWidth: 80, maxWidth: 150, type: 'string', editable: false, },
    { field: 'type', headerName: 'Тип', flex: 1, minWidth: 80, maxWidth: 150, type: 'string', editable: false, },
    { field: 'issueDate', headerName: 'Дата видачі', flex: 1, minWidth: 100, maxWidth: 200, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return ''
            }
            const date = new Date(params.value)
            const valueFormatted = date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + date.getFullYear()
                + ' ' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            return valueFormatted
        }, },
];

export default function SocialCardTable() {
    const { setOkMessage } = useMessages();
    const { data, amountRows, fetchPaginationFilter, isLoading } = useGetMany<ISocialCard>(config.url.SOCIAL_CARD);
    const { fetch: deleteFetch } = useDeleteMany<string>(config.url.SOCIAL_CARD)
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 });
    const [sortField, setSortField] = useState<string>('issueDate');
    const [sortDirection, setSortDirection] = useState<SortingDirection>(SortingDirection.ASC);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [delAvailable, setDelAvailable] = useState<boolean>(false);
    const [amountForDel, setAmountForDel] = useState<number>(0);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilter>({ filterField:'', filterOperator:'', filterValue:'', filterSecondValue:'', type: "string" });

    async function fetchCards(page: number, pageSize: number, sortField: string, sortDirection: SortingDirection, filter: IFilter) {
        setSortField(sortField)
        setSortDirection(sortDirection)
        await fetchPaginationFilter(page, pageSize, sortField, sortDirection , filter);
    }

    useEffect(() => {
        fetchCards(paginationModel.page, paginationModel.pageSize, sortField, sortDirection, filter);
    }, [])

    useEffect(() => {
        fetchCards(paginationModel.page, paginationModel.pageSize, sortField, sortDirection, filter);
    }, [paginationModel])

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        sortModel[0].sort === 'desc' ?
            fetchCards(paginationModel.page, paginationModel.pageSize, sortModel[0].field, SortingDirection.DESC, filter)
            : fetchCards(paginationModel.page, paginationModel.pageSize, sortModel[0].field, SortingDirection.ASC, filter)
    }, []);

    const selectionChangeHandler = (newSelectionModel: GridRowSelectionModel) => {
        if (newSelectionModel.length > 0){
            setDelAvailable(true)
            setAmountForDel(newSelectionModel.length)
        } else {
            setDelAvailable(false)
            setAmountForDel(0)
        }
        setRowSelectionModel(newSelectionModel);
    }

    const buttonClickHandler = () => {
        if (delAvailable) {
            setAlertOpen(true)
        }else{
            setDialogOpen(true)
        }
    }

    const deleteCards = () => {
        const rowsId:string [] = rowSelectionModel.map(value => value as string)
        deleteFetch(rowsId).then(value => {
            fetchCards(paginationModel.page, paginationModel.pageSize, sortField, sortDirection, filter).then(result => {
                setOkMessage('Соціальні карти успішно видалені.')
            })
        })
        setAlertOpen(false)
    }

    const applyFilterHandler = (newFilter: IFilter) => {
        if (JSON.stringify(filter) !== JSON.stringify(newFilter)){
            setFilter(newFilter)
            fetchCards(paginationModel.page, paginationModel.pageSize, sortField, sortDirection, newFilter)
        }
    }

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            {alertOpen &&
            <Alert variant="outlined" severity="warning"
                   action={
                       <>
                           <Button color="inherit" size="small" onClick={deleteCards}> Так </Button>
                           <Button color="inherit" size="small" onClick={() => {
                               setAlertOpen(false)
                           }}> Ні </Button>
                       </>
                   }> {'Кількість карток для видалення = ' + amountForDel + ' од. Ви бажаєте продовжити?'} </Alert>
            }
            <Box sx={{height: '10%', width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: "baseline"}}>
                <FilterButton columns={columns} onSetFilter={ applyFilterHandler } />
                <Fab onClick={buttonClickHandler} color={delAvailable ? 'secondary' : 'primary'} size="medium"
                     aria-label="add" sx={{ marginLeft: 6 }}>
                    {delAvailable ? <DeleteForeverIcon/> : <AddIcon/>}
                </Fab>
            </Box>
            <Box sx={{height: '90%', width: '100%'}}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    loading={isLoading}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[10, 20, 30]}
                    rowCount={amountRows}
                    paginationMode="server"
                    pagination
                    disableColumnFilter
                    checkboxSelection
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={ selectionChangeHandler }
                    disableRowSelectionOnClick
                    autoHeight={true}

                />
            </Box>
            <AddSocialCardDialog isOpen={dialogOpen}
                                  onClosed={ () => { setDialogOpen(false)}}
                                  onCardAdded={ () => { fetchCards(paginationModel.page, paginationModel.pageSize, sortField, sortDirection, filter)}} />
        </Box>
    );
}