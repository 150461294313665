
const prod = {
    url: {
        SERVICE_CARDS: '/api/device/v1/service-card',
        SOCIAL_CARD: '/api/device/v1/social-card',
        SOCIAL_CARDS: '/api/device/v1/social-card/cards',
        FINANCE_REPORT: '/api/report/v1/finance',
        ENCASHMENT_REPORT: '/api/report/v1/encashment',
        SERVICE_REPORT: '/api/report/v1/service',
        MONITORING: '/api/monitor/v1/all',
        LOGIN: '/api/security/v1/login',

    }
};
const dev = {
    url: {
        SERVICE_CARDS: 'http://localhost:8072/device/v1/service-card',
        SOCIAL_CARD: 'http://localhost:8072/device/v1/social-card',
        SOCIAL_CARDS: 'http://localhost:8072/device/v1/social-card/cards',
        FINANCE_REPORT: 'http://localhost:8072/report/v1/finance',
        ENCASHMENT_REPORT: 'http://localhost:8072/report/v1/encashment',
        SERVICE_REPORT: 'http://localhost:8072/report/v1/service',
        MONITORING: 'http://localhost:8072/monitor/v1/all',
        LOGIN: 'http://localhost:8072/security/v1/login',
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
