import React, {createContext, useContext, useState} from "react";
import {role} from "./role";


interface IAuthContext {
    isLogin: boolean
    userRole: keyof typeof role
    updateLogin: (isLogin: boolean) => void
    updateRole: (grantedAuthority: keyof typeof role) => void
}


const AuthContext = createContext<IAuthContext>({
    isLogin: false,
    userRole: 'ROLE_GUEST',
    updateLogin: (isLogin: boolean) => {},
    updateRole: (newRole: keyof typeof role) => {}
});

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthContextProvider = ({ children }: {children: React.ReactNode}) => {
    const [isLogin, setLogin] = useState<boolean>(false)
    const [userRole, setRole] = useState<keyof typeof role>('ROLE_GUEST');

    const updateLogin = (isLogin: boolean) => {
        setLogin(isLogin)
    }

    const updateRole = (userRole: keyof typeof role) => {
        setRole(userRole)
    }

    return (
        <AuthContext.Provider value={{isLogin, updateLogin, userRole, updateRole: updateRole}} >
            { children }
        </AuthContext.Provider>
    )
}

