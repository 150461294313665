import React from "react";
import {ThemeContextProvider} from "./ThemeContext";
import {AuthContextProvider} from "./AuthContext";
import {MessagesContextProvider} from "./MessagesSnackbarContext";

export const AppContextWrapper = ({ children }: {children: React.ReactNode}) => {

    return (
        <AuthContextProvider>
            <MessagesContextProvider>
                    <ThemeContextProvider>
                        { children }
                    </ThemeContextProvider>
            </MessagesContextProvider>
        </AuthContextProvider>


    )
}

