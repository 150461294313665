import React from 'react';
import {HashRouter} from "react-router-dom";
import {AppContextWrapper} from "./context/AppContextWrapper";
import PageContainer from "./pages/PageContainer";

function App() {

  return (
    <HashRouter >
        <AppContextWrapper>
            <PageContainer/>
        </AppContextWrapper>
    </HashRouter>
  );
}

export default App;
