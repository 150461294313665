import {useState} from "react";
import axios from "../../httpService/axiosInterseptor"
import {AxiosError} from "axios"
import {SortingDirection} from "../../data/sortDirection";
import {IFilter} from "../../components/filtration/IFilter";
import {useMessages} from "../../context/MessagesSnackbarContext";

export function useGetMany<T>(resource: string) {
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState<T[]>([])
    const [amountRows, setAmountRows] = useState<number>(5)
    const { setError } = useMessages()

    async function fetchPaginationFilter(page: number, pageSize: number, sort: string, order: SortingDirection, filter: IFilter ) {
        try {
            setLoading(true)
            const response = await axios.get<T[]>(resource, { params: { page: page, pageSize: pageSize, sort: sort, order: order,
                    filterField: filter.filterField, filterOperator: filter.filterOperator,
                    filterValue: filter.filterValue, filterSecondValue:filter.filterSecondValue, type: filter.type } });
            setLoading(false)
            if (response.data.length > 0){
                const rows:string = response.headers['amount-rows']
                setAmountRows(parseInt(rows))
                setData(response.data)
            } else {
                setAmountRows(1)
                setData([])
            }
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(true)
            setError(error.message)
            console.error(error.message)
        }
    }

    async function fetchFilter(filter: IFilter ) {
        try {
            setLoading(true)
            const response = await axios.get<T[]>(resource, { params: {
                    filterField: filter.filterField, filterOperator: filter.filterOperator,
                    filterValue: filter.filterValue, filterSecondValue:filter.filterSecondValue, type: filter.type } });
            setLoading(false)
            if (response.data.length > 0){
                const rows:string = response.headers['amount-rows']
                setAmountRows(parseInt(rows))
                setData(response.data)
            } else {
                setAmountRows(1)
                setData([])
            }
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(true)
            setError(error.message)
            console.error(error.message)
        }
    }

    async function fetchDateBetween(filter: IFilter ) {
        try {
            setLoading(true)
            const response = await axios.get<T[]>(resource, { params: {startDate:filter.filterValue, endDate: filter.filterSecondValue } });
            setLoading(false)
            if (response.data.length > 0){
                const rows:string = response.headers['amount-rows']
                setAmountRows(parseInt(rows))
                setData(response.data)
            } else {
                setAmountRows(1)
                setData([])
            }
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(true)
            setError(error.message)
            console.error(error.message)
        }
    }

    async function fetchAll() {
        try {
            setLoading(true)
            const response = await axios.get<T[]>(resource);
            setLoading(false)
            if (response.data.length > 0){
                const rows:string = response.headers['amount-rows']
                setAmountRows(parseInt(rows))
                setData(response.data)
            } else {
                setAmountRows(1)
                setData([])
            }
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(true)
            setError(error.message)
            console.error(error.message)
        }
    }

    return { data, amountRows, isLoading, fetchPaginationFilter , fetchFilter, fetchDateBetween, fetchAll }
}