import React from 'react'
import {Route, Routes} from "react-router-dom";
import {navigationItems} from "../components/navigationItem";
import MainPage from "./MainPage";
import MonitoringPage from "./MonitoringPage";
import {useAuth} from "../context/AuthContext";
import {Box, CssBaseline} from "@mui/material";
import ProtectedRoute from "../components/ProtectRoute";
import SignInPage from "./SignInPage";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import ReportsPage from "./ReportsPage";
import SocialCardPage from "./SocialCardPage";
import ServiceCardPage from "./ServiceCardPage";
import MessageSnackBar from "../components/messages/MessageSnackbar";

export default function PageContainer() {
    const { isLogin } = useAuth()
    const [open, setOpen] = React.useState(false);
    const [drawerWidth, setDrawerWidth] = React.useState<number>(64);

    const drawerControl = () => {
        setOpen(!open);
        if (!open){
            setDrawerWidth(240)
        }else {
            setDrawerWidth(64)
        }
    }

    return (
        <>
            <CssBaseline/>
            <MessageSnackBar/>
            <Box sx={{display: 'flex', minHeight: '100vh', width: '100%', backgroundColor: 'grey.A100'}}>
                {isLogin ? <ResponsiveDrawer open={ open } drawerWidth={ drawerWidth } openHandler={ drawerControl }/> : <div/>}
                <Box sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: 'grey.A100',
                    p: {md: 2, sm: 1, xs: 1}, mt: { md: 2, sm: 1, xs: 7 }}}>
                    <Routes>
                        <Route path={navigationItems.items[0].path} element={
                            <ProtectedRoute roles={['ROLE_USER', 'ROLE_ADMIN']}>
                                <MainPage/>
                            </ProtectedRoute>}/>
                        <Route path={navigationItems.items[1].path} element={
                            <ProtectedRoute roles={['ROLE_ADMIN']}>
                                <ReportsPage/>
                            </ProtectedRoute>}/>
                        <Route path={navigationItems.items[2].path} element={
                            <ProtectedRoute roles={['ROLE_ADMIN']}>
                                <MonitoringPage/>
                            </ProtectedRoute>}/>
                        <Route path={navigationItems.items[3].path} element={
                            <ProtectedRoute roles={['ROLE_ADMIN']}>
                                <SocialCardPage/>
                            </ProtectedRoute>}/>
                        <Route path={navigationItems.items[4].path} element={
                            <ProtectedRoute roles={['ROLE_ADMIN']}>
                                <ServiceCardPage/>
                            </ProtectedRoute>}/>
                        <Route path={navigationItems.items[6].path} element={<SignInPage roles={[]}/>}/>
                    </Routes>
                </Box>

            </Box>
        </>
    );
}
