import { Box } from '@mui/material'
import React from 'react'
import SocialCardTable from "../components/socialCardTable/SocialCardTable";

export default function SocialCardPage() {

    return (
        <Box sx={{ width: '100%' }}>
            <SocialCardTable/>
        </Box>
    );
}
