
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';

export const navigationItems = {
    items: [
        { name: 'Головна', path: '/', icon: HomeIcon },
        { name: 'Звіти', path: '/reports', icon: AssessmentIcon },
        { name: 'Моніторінг', path: '/monitoring', icon: SupportAgentIcon },
        { name: 'Соціальні карти', path: '/social-cards', icon: Diversity3Icon },
        { name: 'Сервісні карти', path: '/service-cards', icon: SettingsIcon },
        { name: 'Вихід', path: '/', icon: LogoutIcon },
        { name: 'LogIn', path: '/login', icon: LogoutIcon },
        { name: 'Protected', path: '/protected', icon: LogoutIcon },
    ]
}
