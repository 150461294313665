import React, {createContext, useContext, useState} from "react";

interface IThemeContext {
    darkTheme: boolean
    toggleTheme: () => void
}

const ThemeContext = createContext<IThemeContext>({
    darkTheme: false,
    toggleTheme: () => {}

});

export const useTheme = () => {
    return useContext(ThemeContext)
}


export const ThemeContextProvider = ({ children }: {children: React.ReactNode}) => {
    const [darkTheme, setTheme] = useState<boolean>(false)

    const toggleTheme = () => {

    }

    return (
        <ThemeContext.Provider value={{darkTheme, toggleTheme}} >
            { children }
        </ThemeContext.Provider>
    )
}

