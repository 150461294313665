
export function dateConvert(dateStr: string):string {
    let normalDate = ''
    try{
        const date = new Date(dateStr)
        normalDate = date.getFullYear()
            + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            + '-' + date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            + 'T' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    } catch (e) {

    }
    return normalDate
}

