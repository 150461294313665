import React, {useEffect, useState} from "react";
import {Badge, BadgeProps, IconButton} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ActiveErrorsModal from "./ActiveErrorsModal";
import {IMessage} from "./IMessage";
import {styled} from "@mui/material/styles";

interface IMessagesIconSetProps {
    value: string | undefined
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -5,
        top: 6,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export function MessagesIconSet(props: IMessagesIconSetProps) {
    const { value } = props;
    const [isOpen, setOpen] = useState<boolean>(false)
    const [messages, setMessages] = useState<IMessage[]>([])

    useEffect(() => {
        setMessages(getActiveMessages(value))
    }, [value])

    function getActiveMessages(value: string | undefined): IMessage[] {
        if (value && typeof value === "string" && value !== '') {
            let messages: IMessage[] = JSON.parse(value);
            const iMessages = messages.filter(message => {
                return message.state === 'ACTIVE'
            });
            return iMessages
        } else {
            return []
        }
    }

    const getIconValue = (val: string | undefined):{isOk:boolean, numError: number, numWsrning:number} => {
        if( messages.length > 0 ){
            let amountWarnings:number = 0
            let amountErrors:number = 0
            for (const message of messages) {
                if (message.type === 'WARNING'){
                    amountWarnings++
                }else if (message.type === 'ERROR'){
                    amountErrors++
                }
            }
            return {isOk:false, numError:amountErrors, numWsrning:amountWarnings}
        } else {
            return {isOk:true, numError:0, numWsrning:0}
        }
    }

    const valueHandler = () => {
        setOpen(true)
    }

    const iconValue = getIconValue(value);

    return (
        <strong>
            <IconButton size="small" onClick={ valueHandler } >
                { iconValue.isOk && <CheckCircleOutlineIcon color="success"/> }
                { (iconValue.numError > 0) &&
                <StyledBadge badgeContent={iconValue.numError} color="secondary">
                    <NewReleasesIcon color="error"/>
                </StyledBadge>
                }
                { (iconValue.numWsrning > 0) &&
                <StyledBadge badgeContent={iconValue.numWsrning} color="secondary">
                    <WarningAmberIcon color="warning"/>
                </StyledBadge>
                }
            </IconButton >
            <ActiveErrorsModal open={isOpen} setOpen={ open => setOpen(open) } messages={ messages } />
        </strong>
    )

}