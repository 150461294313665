import {GridColType} from "@mui/x-data-grid/models/colDef/gridColType";

export declare type FilterOperation = 'CONTAINS' | 'EQUALS' | 'IS_AFTER' | 'IS_BEFORE' | 'BETWEEN' | 'GREAT_THAN' | 'LESS_THAN' | 'NOT_EQUALS'


export function filterOperations(type: GridColType | undefined): FilterOperation[] {
    if (type === "string"){
        return ["CONTAINS", "EQUALS"]
    } else if (type === 'dateTime' || type === 'date'){
        return ['IS_AFTER', 'IS_BEFORE', 'BETWEEN']
    } else if (type === "number"){
        return ['GREAT_THAN', 'LESS_THAN', 'EQUALS', 'NOT_EQUALS']
    } else if (type === 'boolean'){
        return []
    } else if (type === undefined) {
        return []
    } else {
        return []
    }
}

export function operationName(operation: FilterOperation): string {
    switch (operation) {
        case "BETWEEN": return 'проміж'
        case "CONTAINS": return 'має'
        case "EQUALS": return 'дорівнює'
        case "GREAT_THAN": return 'більше чім'
        case "IS_AFTER": return 'після'
        case "IS_BEFORE": return 'до'
        case "LESS_THAN": return 'меньше ніж'
        case "NOT_EQUALS": return  'не дорівнює'
        default: return 'невідомо'
    }
}
