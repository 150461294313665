import React, {useState} from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {dateConvert} from "../../data/dateConverter";
import {ReportType} from "./ReportType";
import {IReportFilter} from "./IReportFilter";

interface IReportSelectorProps {
    onApply(newFilter: IReportFilter): void
}

export default function ReportSelector(props: IReportSelectorProps) {
    const [filter, setFilter] = useState<IReportFilter>({reportType:'UNKNOWN', endDateTime: '', startDateTime: ''})
    const [isIncorrectValue, setIncorrectValue] = useState<boolean>(false);
    const [valueHelpText, setValueHelpText] = useState<string>("");

    const handleFieldChange = (event: SelectChangeEvent) => {
        const newFilter = {...filter}
        newFilter.reportType = event.target.value as ReportType
        setFilter(newFilter)
    };

    const startValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilter = {...filter}
        newFilter.startDateTime = event.target.value
        setFilter(newFilter)
        setIncorrectValue(false)
        setValueHelpText('')
    }

    const endValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilter = {...filter}
        newFilter.endDateTime = event.target.value
        setFilter(newFilter)
        setIncorrectValue(false)
        setValueHelpText('')
    }

    const applyFilterHandler = () => {
        if (filter.startDateTime.length === 0 || filter.endDateTime.length  === 0 ){
            // TODO add validation
            setIncorrectValue(true)
            setValueHelpText('Значення не повинно бути пустим.')
        } else {
            props.onApply(filter)
        }
    }

    return (
        <Box sx={{ display:'flex', flexWrap:'wrap' }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="field-select-label">Звіт</InputLabel>
                <Select labelId="field-select-label" id="field-select"  label="Стовбець"
                        value={ filter.reportType }
                        onChange={ handleFieldChange } >
                    <MenuItem value='UNKNOWN'> <em>Немає</em> </MenuItem>
                    <MenuItem value='FINANCE'>Фінанси</MenuItem>
                    <MenuItem value='ENCASHMENT'>Інкассації</MenuItem>
                    <MenuItem value='SERVICE'>Прибирання</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{ display:'flex', flexWrap:'wrap' }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
                    <TextField id="filter-value" label="Початок" variant="standard" type="datetime-local" error={ isIncorrectValue } helperText={ valueHelpText }
                               value={ dateConvert(filter.startDateTime) }
                               onChange={ startValueChangeHandler } disabled={filter.reportType === 'UNKNOWN' ? true : false} InputLabelProps={{ shrink: true, }} />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
                    <TextField id="filter-value" label="Кінець" variant="standard" type="datetime-local" error={ isIncorrectValue } helperText={ valueHelpText }
                               value={ dateConvert(filter.endDateTime) }
                               onChange={ endValueChangeHandler } disabled={filter.reportType === 'UNKNOWN' ? true : false} InputLabelProps={{ shrink: true, }} />
                </FormControl>
            </Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
                <Button variant="outlined" sx={{margin: 1}} onClick={ applyFilterHandler } disabled={filter.reportType === 'UNKNOWN' ? true : false}>Показати</Button>
            </FormControl>
        </Box>
    )

}