
import {useAuth} from "../context/AuthContext";
import React, {useEffect} from "react";
import {role} from "../context/role";
import SignInPage from "../pages/SignInPage";
import axios from "../httpService/axiosInterseptor"
import {AxiosError, AxiosResponse} from "axios";
import {config} from "../data/Constants";

type Props = {
    roles: string[]
    children?: React.ReactNode
};

export default function ProtectedRoute(props:Props) {
    const {isLogin, updateLogin, userRole, updateRole} = useAuth();

    useEffect(() => {
        if (!isLogin) {
            getRole()
        }
    }, [])

    function getRole(){
            //TODO decode JWT token for getting the role
    }
    return(
        <>
            { (isLogin && props.roles.includes(userRole)) ? props.children : <SignInPage roles={ props.roles } />}
        </>
    )

}