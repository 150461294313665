import * as React from 'react';
import {Avatar, Box, Typography, Container, CssBaseline, TextField} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios, {AxiosError} from "axios";
import {useAuth} from "../context/AuthContext";
import {useState} from "react";
import {config} from "../data/Constants";
import {useMessages} from "../context/MessagesSnackbarContext";

const theme = createTheme();

interface ISignInPageProps{
    roles: string[]
}

interface ICredential {
    token:string
    username:string
    authorities: "ROLE_USER" | "ROLE_ADMIN" | "ROLE_GUEST"
}

export default function SignInPage(props: ISignInPageProps) {
    const {updateLogin, updateRole} = useAuth();
    const [isLoading, setLoading] = useState<boolean>(false);
    const { setError } = useMessages()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const username:any = formData.get('username')
        const password:any =  formData.get('password')
        feethAuth(username, password)
    };

    async function feethAuth(username: any, password: any){
        try{
            setLoading(true)
            const response = await axios.post<ICredential>(config.url.LOGIN, {username: username, password: password})
            setLoading(false)
            updateLogin(true)
            //TODO decode JWT token for getting the role
            updateRole(response.data.authorities)
            localStorage.setItem("wc-token", response.data.token);
        } catch (e: unknown) {
            setLoading(false)
            const error = e as AxiosError
            setError(error.message)
            console.log(error)
        }


    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                {process.env.NODE_ENV !== 'production' && <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>}
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5"> Sign in </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField margin="normal" required fullWidth disabled={isLoading} id="username" label="username" name="username"
                                   autoFocus />
                        <TextField margin="normal" required fullWidth disabled={isLoading} name="password" label="Password" type="password" id="password"
                            autoComplete="current-password" />
                        <LoadingButton type="submit" fullWidth variant="contained" loading={isLoading} sx={{ mt: 3, mb: 2 }} > Sign In </LoadingButton>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}