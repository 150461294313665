import axios, {AxiosError} from 'axios';

// axios instance for making requests
const axiosInstance = axios.create();


// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
    // add token to request headers
    const token = localStorage.getItem('wc-token')
    config.headers!.Authorization = `Bearer ${token}`
    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    function (e: unknown) {
        const error = e as AxiosError
        if ( !error.config?.url?.endsWith('role')){
            if (error.response?.status === 401 || error.response?.status === 403) {
                //TODO maybe create a some delay
                window.location.href = '/'
            }
        }
        return Promise.reject(error)
    }
)

export default axiosInstance;