import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";
import {usePostOne} from "../../hooks/dataProvider/usePostOne";
import {config} from "../../data/Constants";
import {useMessages} from "../../context/MessagesSnackbarContext";

interface IAddServiceCardDialogProps{
    isOpen:boolean
    onClosed(): void
    onCardAdded(): void
}

interface IServiceCard {
    id: string
    name: string
    issueDate: Date
}


export default function AddServiceCardDialog(props: IAddServiceCardDialogProps) {
    const { setOkMessage } = useMessages();
    const { isOpen, onClosed, onCardAdded} = props
    const { isLoading, fetch } = usePostOne<IServiceCard>(config.url.SERVICE_CARDS)
    const[newCard, setNewCard] = useState<IServiceCard>({id: '', name: '', issueDate: new Date()});
    const [isIncorrectUidValue, setIncorrectUidValue] = useState<boolean>(false);
    const [uidHelpText, setUidHelpText] = useState<string>("");
    const [isIncorrectNameValue, setIncorrectNameValue] = useState<boolean>(false);
    const [nameHelpText, setNameHelpText] = useState<string>("");

    const handleClose = () => {
        setIncorrectUidValue(false)
        setUidHelpText('')
        setIncorrectNameValue(false)
        setNameHelpText('')
        onClosed()
    }

    const handleSave = () => {
        if (validateUID(newCard.id) && validateName(newCard.name)){
            fetch(newCard).then(value => {
                setOkMessage('Картка успішно добавлена.')
                onCardAdded()
                onClosed()
            })
        }
    }

    const pattern = /^[0-9A-F]{2}\ [0-9A-F]{2}\ [0-9A-F]{2}\ [0-9A-F]{2}$/i;
    const validateUID = (uid:string):boolean => {
        const result:boolean = pattern.test(uid);
        if (result){
            setIncorrectUidValue(false)
            setUidHelpText('')
            return true;
        } else {
            setIncorrectUidValue(true)
            setUidHelpText("некоректне значення (приклад '01 2F 54 A5')")
            return false;
        }
    };

    const validateName = (name:string):boolean => {
        if (name.length < 5){
            setIncorrectNameValue(true)
            setNameHelpText("Ім'я повинно бути більше ніж 5-ть символів." )
            return false
        } else if(name.length > 30) {
            setIncorrectNameValue(true)
            setNameHelpText("Ім'я повинно бути меньше ніж 30-ть символів.")
            return false
        } else {
            setIncorrectNameValue(false)
            setNameHelpText("")
            return true
        }
    };

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Нова сервісна картка</DialogTitle>
                <DialogContent>
                    <DialogContentText>Внесіть данні у відповідні поля</DialogContentText>
                    <TextField autoFocus margin="dense" id="serviceCardUID" label="UID картки" type="text" fullWidth variant="standard"
                               inputProps={{size: 11, maxLength: 11, minLength: 6}}
                               error={ isIncorrectUidValue } helperText={ uidHelpText }
                               onChange={newValue => {
                                    let tmpCard = {...newCard}
                                    tmpCard.id = newValue.target.value
                                    setNewCard(tmpCard)}
                                }
                    />
                    <TextField autoFocus margin="dense" id="serviceCardNmae" label="Ім'я картки" type="text" fullWidth variant="standard"
                               inputProps={{size: 30, maxLength: 30, minLength: 5}}
                               error={ isIncorrectNameValue } helperText={ nameHelpText }
                               onChange={newValue => {
                                   let tmpCard = {...newCard}
                                   tmpCard.name = newValue.target.value
                                   setNewCard(tmpCard)}
                               }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } variant="outlined" >Відміна</Button>
                    <Button onClick={ handleSave } variant="outlined" >Зберегти</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}