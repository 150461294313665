export function dateFormat(formatDate: Date):string {
    if (formatDate == null) {
        return ''
    }
    const date = new Date(formatDate)
    const valueFormatted = date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + '-' + date.getFullYear()
        + ' ' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    return valueFormatted
}