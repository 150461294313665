import React, {createContext, useContext, useState} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";


interface IMessageSnackbarContext {
    message: string
    isPresence: boolean
    messageType: 'error' | 'warning' | 'success' | undefined
    clearMessage: () => void
    setError: (newError: string) => void
    setWarning: (newWarning: string) => void
    setOkMessage: (newOkMessage: string) => void
}

const MessageSnackbarContext = createContext<IMessageSnackbarContext>({
    message: '',
    isPresence: false,
    messageType: undefined,
    clearMessage: () => {},
    setError: (newError: string) => {},
    setWarning: (newWarning: string) => {},
    setOkMessage: (newOkMessage: string) => {}
});

export const useMessages = () => {
    return useContext(MessageSnackbarContext)
}

export const MessagesContextProvider = ({ children }: {children: React.ReactNode}) => {
    const [message, setMessage] = useState<string>('')
    const [isPresence, setIsPresence] = useState<boolean>(false)
    const [messageType, setMessageType] = useState<'error' | 'warning' | 'success' | undefined>(undefined)

    const clearMessage = () => {
        setMessage('')
        setIsPresence(false)
        setMessageType(undefined)
    }

    const setError = (newError: string) => {
        setMessage(newError)
        setMessageType('error')
        setIsPresence(true)
    }

    const setWarning = (newWarning: string) => {
        setMessage(newWarning)
        setMessageType('warning')
        setIsPresence(true)
    }

    const setOkMessage = (newOkMessage: string) => {
        setMessage(newOkMessage)
        setMessageType('success')
        setIsPresence(true)
    }

    return (
        <MessageSnackbarContext.Provider value={{ message, isPresence, messageType, clearMessage, setError, setWarning, setOkMessage }} >
            { children }
        </MessageSnackbarContext.Provider>
    )
}

