import { Box, } from '@mui/material'
import React from 'react'
import MonitoringTable from "../components/monitoring/MonitoringTable";

export default function MonitoringPage() {

    return (
        <Box sx={{ width: '100%' }}>
            < MonitoringTable />
        </Box>
    );
}
