import {useState} from "react";
import axios from "../../httpService/axiosInterseptor"
import {AxiosError} from "axios"
import {useMessages} from "../../context/MessagesSnackbarContext";

export function usePostOne<T>(resource: string) {
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState<T>()
    const { setError } = useMessages()

    async function fetch(dto: T) {
        try {
            setLoading(true)
            const response = await axios.post<T>(resource, dto);
            setLoading(false)
            setData(response.data)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(true)
            setError(error.message)
            console.error(error.message)
        }
    }

    return { data, isLoading, fetch }
}