import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GridColDef, GridFooter, GridFooterContainer,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport,
    GridToolbarFilterButton,
    GridValueFormatterParams, useGridApiContext, useGridApiRef
} from '@mui/x-data-grid';
import {useGetMany} from "../../hooks/dataProvider/useGetMany";
import {config} from "../../data/Constants";
import {IReportFilter} from "./IReportFilter";
import {useEffect, useMemo, useRef, useState} from "react";
import {IFilter} from "../filtration/IFilter";
import {dateConvert} from "../../data/dateConverter";

interface IRows{
    rows:{
        ids:number[],
        totalRowCount:number
    }
}

interface IFinanceReport {
    id: number
    region: string
    city: string
    address: string
    numberTransactions: number
    amountNfc: number
    sumNfc: number
    amountCash: number
    sumCash: number
    amountVirtual: number
    sumVirtual: number
    sum: number
}

function formatToGrn(params: GridValueFormatterParams<number>) {
    if (params.value == null) {
        return ''
    }
    return params.value / 100
}

const columns: GridColDef[] = [
    { field: 'id', headerName: '№', flex: 1, minWidth: 70, type: 'number', editable: false },
    { field: 'region', headerName: 'Область', flex: 1, minWidth: 150, type: 'string', editable: false, },
    { field: 'city', headerName: 'Місто', flex: 1, minWidth: 150, type: 'string', editable: false, },
    { field: 'address', headerName: 'Адрес', flex: 1, minWidth: 200, type: 'string', editable: false, },
    { field: 'numberTransactions', headerName: 'Кількість транзакцій', flex: 1, minWidth: 150, type: 'number', editable: false, },
    { field: 'amountNfc', headerName: 'Кількість NFC', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'sumNfc', headerName: 'Сума NFC', flex: 1, minWidth: 100, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            return formatToGrn(params)
        },},
    { field: 'amountCash', headerName: 'Кількість готівкою', flex: 1, minWidth: 140, type: 'number', editable: false, },
    { field: 'sumCash', headerName: 'Сума готівкою', flex: 1, minWidth: 120, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            return formatToGrn(params)
        },},
    { field: 'amountVirtual', headerName: 'Кількість віртуально', flex: 1, minWidth: 160, type: 'number', editable: false, },
    { field: 'sumVirtual', headerName: 'Сума віртуально', flex: 1, minWidth: 130, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            return formatToGrn(params)
        },},
    { field: 'sum', headerName: 'Загальна сума', flex: 1, minWidth: 120, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            return formatToGrn(params)
        },},
];

export default function FinanceReportTable(props: {filter: IReportFilter}) {
    const { data, fetchDateBetween ,isLoading } = useGetMany<IFinanceReport>(config.url.FINANCE_REPORT);
    const [numTransactions, setNumTransactions] = useState<number>(0)

    useEffect(() => {
        const filter: IFilter = { filterOperator:'BETWEEN', filterField: 'dateTime',
            filterValue: dateConvert(props.filter.startDateTime), filterSecondValue:dateConvert(props.filter.endDateTime) }
        fetchDateBetween(filter)
    }, [props.filter])

    function CustomToolbar() {
        // @ts-ignore
        // @ts-ignore
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    function CustomFooter () {
        // const apiRef = useGridApiContext();

        return (
            <GridFooterContainer>
                {/*<Typography>{transNum}</Typography>*/}
                {/* Add what you want here */}
                {/*<GridFooter sx={{*/}
                {/*    border: 'none', // To delete double border.*/}
                {/*}} />*/}
            </GridFooterContainer>
        );
    }


    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <DataGrid
                rows={data}
                columns={columns}
                loading={isLoading}
                      // hideFooterRowCount={true}
                      hideFooterSelectedRowCount={true}
                      hideFooterPagination={true}
                autoHeight={true}

                components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooter
                }}
            />
        </Box>
    );
}