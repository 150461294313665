import * as React from 'react';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    IconButton, Slide,
    Toolbar, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {config} from "../../data/Constants";
import {ISocialCard} from "./ISocialCard";
import {usePostMany} from "../../hooks/dataProvider/usePostMany";
import {TransitionProps} from "@mui/material/transitions";
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridToolbar,
    GridValueFormatterParams
} from "@mui/x-data-grid";
import {useState} from "react";
import {useMessages} from "../../context/MessagesSnackbarContext";

interface IAddSocialCardsDialogProps{
    cards: ISocialCard[]
    isOpen: boolean
    onClosed(): void
    onCardAdded(): void
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'UID', flex: 1, minWidth: 80, maxWidth: 250, type: 'string', editable: false, sortable: false },
    { field: 'firstName', headerName: 'Ім`я', flex: 1, maxWidth: 150, type: 'string', editable: false, },
    { field: 'lastName', headerName: 'Фамілія', flex: 1, maxWidth: 150, type: 'string', editable: false, },
    { field: 'data', headerName: 'Додаткові данні', flex: 1, maxWidth: 150, type: 'string', editable: false, },
    { field: 'type', headerName: 'Тип', flex: 1, maxWidth: 150, type: 'string', editable: false, },
];

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddSocialCardDialog(props: IAddSocialCardsDialogProps) {
    const { setOkMessage } = useMessages();
    const { cards, isOpen, onClosed, onCardAdded } = props
    const { isLoading, fetch } = usePostMany<ISocialCard>(config.url.SOCIAL_CARDS)
    // const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);


    const handleClose = () => {
        onClosed()
        onCardAdded()
    }

    const handleSaveAll = () => {
        fetch(cards).then(value => {
            setOkMessage('Всі соціальні картки успішно збережені.')
            handleClose()
        })
    }

    const handleSaveSelected = () => {
        const rowsId:string [] = rowSelectionModel.map(value => value as string)
        const filteredCards = cards.filter(value => {
            return rowsId.includes(value.id)
        })
        fetch(filteredCards).then(value => {
            setOkMessage('Вибрані соціальні картки успішно збережені.')
            handleClose()
        })
    }

    const selectionChangeHandler = (newSelectionModel: GridRowSelectionModel) => {
        setRowSelectionModel(newSelectionModel);
    }

    return (
        <>
            <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Перелік карток для збереження
                        </Typography>
                        {
                            rowSelectionModel.length === 0 ?
                                <Button autoFocus color="inherit" onClick={ handleSaveAll }>
                                    Зберегти все
                                </Button>
                                :
                                <Button autoFocus color="inherit" onClick={ handleSaveSelected }>
                                    Зберегти вибране
                                </Button>
                        }

                    </Toolbar>
                </AppBar>
                <Box  style={{ flexGrow: 1 }} >
                    <DataGrid
                        rows={cards}
                        columns={columns}
                        autoHeight
                        checkboxSelection
                        // disableSelectionOnClick
                        // onSelectionModelChange={selectionChangeHandler}
                        // selectionModel={selectionModel}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={ selectionChangeHandler }
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        hideFooterPagination={true}
                    />
                </Box>

            </Dialog>
        </>
    );
}