import React, {useEffect, useState} from "react";
import { Button } from "@mui/material";

interface IDeviceNumberButtonProps {
    value: number | undefined
    ip: string | undefined
}

export function DeviceNumberButton(props: IDeviceNumberButtonProps) {
    const { value, ip } = props;
    const [ipAddress, setIpAddress] = useState<string>("")

    useEffect(() => {
        setIpAddress("http://" + ip)
    }, [value])

    const clickHandler = () => {
        window.open(ipAddress);
        // window.location.href = ipAddress
    }

    return (
        <Button variant="text" onClick={ clickHandler }>
            {value}
        </Button>
    )

}