import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton,
    GridValueFormatterParams
} from '@mui/x-data-grid';
import {useGetMany} from "../../hooks/dataProvider/useGetMany";
import {config} from "../../data/Constants";
import {IReportFilter} from "./IReportFilter";
import {useEffect} from "react";
import {IFilter} from "../filtration/IFilter";
import {dateConvert} from "../../data/dateConverter";

interface IAccountingServiceWorker {
    id: number
    deviceNumber:number
    cardName: string
    dateTimeStart: Date
    dateTimeEnd: Date
    cleaningTimeMs: number
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1, minWidth: 30, type: 'number', editable: false },
    { field: 'deviceNumber', headerName: '№', flex: 1, minWidth: 80, type: 'number', editable: false, },
    { field: 'cardName', headerName: 'Ім`я карти', flex: 1, minWidth: 200, type: 'string', editable: false, },
    { field: 'dateTimeStart', headerName: 'Початок прибирання', flex: 1, minWidth: 200, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return ''
            }
            const date = new Date(params.value)
            const valueFormatted = date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + date.getFullYear()
                + ' ' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            return valueFormatted
        },},
    { field: 'dateTimeEnd', headerName: 'Кінець прибирання', flex: 1, minWidth: 200, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return ''
            }
            const date = new Date(params.value)
            const valueFormatted = date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + date.getFullYear()
                + ' ' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            return valueFormatted
        },},
    { field: 'cleaningTimeMs', headerName: 'Час прибирання', flex: 1, minWidth: 160, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            if (params.value == null) {
                return ''
            }
            const minutes = params.value / 1000 / 60
            return Math.round(minutes) + ' хв.'
        },},
];

export default function AccountingServiceWorkerTable(props: {filter: IReportFilter}) {
    const { data, fetchDateBetween ,isLoading } = useGetMany<IAccountingServiceWorker>(config.url.SERVICE_REPORT);

    useEffect(() => {
        const filter: IFilter = { filterOperator:'BETWEEN', filterField: 'dateTime',
            filterValue: dateConvert(props.filter.startDateTime), filterSecondValue:dateConvert(props.filter.endDateTime) }
        fetchDateBetween(filter)
    }, [props.filter])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <DataGrid
                rows={data}
                columns={columns}
                loading={isLoading}
                hideFooter={true}
                autoHeight={true}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </Box>
    );
}