import { Box, Paper, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react'
import {grey} from "@mui/material/colors";

export default function MainPage() {

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={4} flexWrap={'wrap-reverse'} >
                <Grid xs={12} md={9} >
                    <Paper>
                        <Stack sx={{ padding:{ md: 2, xs: 0 } }}>
                            <Typography ml={2} color={grey[700]} >Добрий день!</Typography>
                            <Typography variant={'body2'} ml={4} mt={1} color={grey[600]} >Тут поки нічого немає.</Typography>
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={4}>
                                    <div/>
                                </Grid>
                                <Grid xs={12} sm={12} md={4}>
                                    <div/>
                                </Grid>
                                <Grid xs={12} sm={12} md={4}>
                                    <div/>
                                </Grid>
                            </Grid>
                            <Box m={4} ml={{ md:8, xs:1 }} mr={{ md:8, xs:1 }} >
                                <div/>
                            </Box>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid xs={12} md={3} >
                    <div/>
                    <Box m={3} mb={1}>
                        <div/>
                    </Box>

                </Grid>
            </Grid>
        </Box>
    );
}
