import React, {useState} from 'react'
import {Avatar, Badge, Dialog, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import {blue} from "@mui/material/colors";
import {dateFormat} from "../../data/dateFormat";
import {IMessage} from "./IMessage";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IDialogProps {
    open: boolean
    setOpen: (value: boolean) => void;
    messages: IMessage[]
}

interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function ActiveErrorsModal(props: IDialogProps) {
    useState<boolean>(false)
    let { open, setOpen, messages } = props;

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <Dialog onClose={handleClose} open={open} scroll={"body"}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >Активні помилки</BootstrapDialogTitle>
            <DialogContent dividers>
                <List sx={{pt: 0}} dense={true}>
                    {messages.map((message, index:number) => (
                        <ListItem disableGutters key={message.id}>
                            <ListItemButton >
                                <ListItemAvatar>
                                    <Avatar sx={{bgcolor: blue[100]}}>
                                        { message.type === 'ERROR'
                                            ? <ErrorIcon color="error" fontSize="large" />
                                            : <WarningAmberIcon color="warning" fontSize="large" /> }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={ message.message + ' ' + message.additional_message }
                                              secondary={ 'початок: ' + dateFormat(message.start_date_time) }/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
}