import * as React from 'react';
import {
    Badge,
    Box, Button, Divider,
    Fade,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Popper,
    Select, SelectChangeEvent, TextField,
    Typography
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {GridColDef} from "@mui/x-data-grid";
import {IFilter} from "./IFilter";
import {filterOperations, operationName} from "./FilterOperation";
import {useState} from "react";
import {dateConvert} from "../../data/dateConverter";

interface  IFilterButtonProps{
    columns: GridColDef[]
    onSetFilter(newFilter: IFilter): void
}

export default function FilterButton(props: IFilterButtonProps) {
    const { columns, onSetFilter } = {...props}
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [filter, setFilter] = useState<IFilter>({ filterField:'', filterOperator:'', filterValue:'', filterSecondValue:'', type: "string" });
    const [isIncorrectValue, setIncorrectValue] = useState<boolean>(false);
    const [valueHelpText, setValueHelpText] = useState<string>("");

    const filterButtonHandle = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const clearFilter = () => {
        const emptyFilter = {filterField:'', filterOperator:'', filterValue:'', filterSecondValue:'', type:'string'}
        setFilter(emptyFilter)
        setIncorrectValue(false)
        setValueHelpText('')
        onSetFilter(emptyFilter)
    }

    const handleFieldChange = (event: SelectChangeEvent) => {
        if (event.target.value === ''){
            clearFilter()
            return
        }
        const column = columns.find(value => value.field === event.target.value)
        if (column != undefined){
            const newFilter = {...filter}
            newFilter.filterField = event.target.value
            newFilter.type = column.type
            newFilter.filterOperator = ''
            newFilter.filterValue = ''
            setFilter(newFilter)
        }
    };

    const handleOperationChange = (event: SelectChangeEvent) => {
        const newFilter = {...filter}
        newFilter.filterOperator = event.target.value
        setFilter(newFilter)
    };

    const valueChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilter = {...filter}
        newFilter.filterValue = event.target.value
        setFilter(newFilter)
        setIncorrectValue(false)
        setValueHelpText('')
    }

    const secondValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFilter = {...filter}
        newFilter.filterSecondValue = event.target.value
        setFilter(newFilter)
        setIncorrectValue(false)
        setValueHelpText('')
    }

    const selectInputField = () => {
        switch(filter.type) {
            case "string":   return <TextField id="filter-value" label="Значення" variant="standard" error={ isIncorrectValue } helperText={ valueHelpText }
                                                value={filter.filterValue} onChange={ valueChangeHandler } disabled={filter.filterField === '' ? true : false} />;
            case "number":   return <TextField id="filter-value" label="Значення" variant="standard" type="number" error={ isIncorrectValue } helperText={ valueHelpText }
                                               value={filter.filterValue} onChange={ valueChangeHandler } disabled={filter.filterField === '' ? true : false}/>;
            case "dateTime": return <TextField id="filter-value" label="Значення" variant="standard" type="datetime-local" error={ isIncorrectValue } helperText={ valueHelpText }
                                               value={ dateConvert(filter.filterValue) } onChange={ valueChangeHandler } disabled={filter.filterField === '' ? true : false} InputLabelProps={{ shrink: true, }} />
            case "date":  return <TextField id="filter-value" label="Значення" variant="standard" type="date" error={ isIncorrectValue } helperText={ valueHelpText }
                                            value={filter.filterValue} onChange={ valueChangeHandler } disabled={filter.filterField === '' ? true : false} InputLabelProps={{ shrink: true, }} />
            default:      return <h1>No project match</h1>
        }
    }

    const applyFilterHandler = () => {
        if (filter.type === 'string' && filter.filterValue.length < 4){
            setIncorrectValue(true)
            setValueHelpText('Значення повинно бути більше 3-х символів.')
        }else if (filter.type === 'number' && filter.filterValue.length === 0 ){
            // TODO add validation
            setIncorrectValue(true)
            setValueHelpText('Значення не повинно бути пустим.')
        } else if (filter.type === 'dateTime' && filter.filterValue.length === 0 ){
            // TODO add validation
            setIncorrectValue(true)
            setValueHelpText('Значення не повинно бути пустим.')
        } else {
            onSetFilter(filter)
        }
    }

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <div>
            <IconButton  aria-describedby={id} aria-label="filter" size="medium" onClick={ filterButtonHandle } >
                <Badge variant="dot" color="secondary" invisible={ filter.filterValue === '' ? true : false } >
                    <FilterAltIcon fontSize="large" />
                </Badge>
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} transition placement='bottom-start' >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ p: 2 }} >
                            <Box>
                                <Typography sx={{ p: 2 }}>Фільтрація по обраному стовбцю.</Typography>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="field-select-label">Стовбець</InputLabel>
                                    <Select labelId="field-select-label" id="field-select"  label="Стовбець"
                                            value={ filter.filterField }
                                            onChange={ handleFieldChange } >
                                        <MenuItem value=''>
                                            <em>None</em>
                                        </MenuItem>
                                        { columns.map((column, index) => (
                                            <MenuItem key={index} value={column.field}>{column.headerName}</MenuItem>
                                        )) }
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={filter.filterField === '' ? true : false}>
                                    <InputLabel id="operation-select-label">Операція</InputLabel>
                                    <Select labelId="operation-select-label" id="operation-select"  label="Операція"
                                            value={ filter.filterOperator }
                                            onChange={ handleOperationChange } >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        { filterOperations(filter.type).map((operation, index) => (
                                            <MenuItem key={index} value={operation}>{operationName(operation)}</MenuItem>
                                        )) }
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
                                    { selectInputField() }
                                </FormControl>
                                { filter.filterOperator === 'BETWEEN' &&
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
                                            <TextField id="filter-value" label="Значення" variant="standard" type="datetime-local" error={ isIncorrectValue } helperText={ valueHelpText }
                                                    value={ dateConvert(filter.filterSecondValue) } onChange={ secondValueChangeHandler } disabled={filter.filterField === '' ? true : false} InputLabelProps={{ shrink: true, }} />
                                        </FormControl>
                                }
                            </Box>
                            <Divider />
                            <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                                <Button variant="outlined" sx={{margin: 1}} onClick={ clearFilter }>Зкинути</Button>
                                <Button variant="outlined" sx={{margin: 1}} onClick={ applyFilterHandler } disabled={filter.filterOperator === '' ? true : false}>Застосувати</Button>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    )
}