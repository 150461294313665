import {Box} from '@mui/material'
import React, {useState} from 'react'
import ReportSelector from "../components/reports/ReportSelector";
import {IReportFilter} from "../components/reports/IReportFilter";
import FinanceReportTable from "../components/reports/FinanceReportTable";
import EncashmentReportTable from "../components/reports/EncashmentReportTable";
import AccountingServiceWorkerTable from "../components/reports/AccountingServiceWorkerTable";

export default function ReportsPage() {
    const [filter, setFilter] = useState<IReportFilter>({reportType:'UNKNOWN', endDateTime: '', startDateTime: ''})

    const selectInputField = () => {
        switch(filter.reportType) {
            case "FINANCE": return <FinanceReportTable filter={filter} />;
            case "ENCASHMENT":return <EncashmentReportTable filter={filter} />
            case "SERVICE":return <AccountingServiceWorkerTable filter={filter} />
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box  >
                <ReportSelector onApply={newFilter => setFilter(newFilter)} />
            </Box>
            <Box  >
                { selectInputField() }
            </Box>

        </Box>
    );
}
