import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport,
    GridToolbarFilterButton,
    GridValueFormatterParams
} from '@mui/x-data-grid';
import {useGetMany} from "../../hooks/dataProvider/useGetMany";
import {config} from "../../data/Constants";
import {IReportFilter} from "./IReportFilter";
import {useEffect} from "react";
import {IFilter} from "../filtration/IFilter";
import {dateConvert} from "../../data/dateConverter";

interface IEncashmentReport {
    id: string
    dateTime: Date
    number: number
    sum: number
    amountCoins: number
    kop_500: number
    kop_1000: number
}

const columns: GridColDef[] = [
    { field: 'id', headerName: '№', flex: 1, minWidth: 150, type: 'string', editable: false },
    { field: 'dateTime', headerName: 'Дата/час', flex: 1, minWidth: 180, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            if (params.value == null) {
                return ''
            }
            const date = new Date(params.value)
            const valueFormatted = date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + '-' + date.getFullYear()
                + ' ' + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
                + ':' + date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
            return valueFormatted
        },},
    { field: 'number', headerName: 'Номер', flex: 1, minWidth: 80, type: 'number', editable: false, },
    { field: 'sum', headerName: 'Сума', flex: 1, minWidth: 100, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            if (params.value == null) {
                return ''
            }
            return params.value  / 100
        },},
    { field: 'amountCoins', headerName: 'Кількість монет', flex: 1, minWidth: 130, type: 'number', editable: false, },
    { field: 'kop_500', headerName: 'Кількість 5грн', flex: 1, minWidth: 120, type: 'number', editable: false, },
    { field: 'kop_1000', headerName: 'Кількість 10грн', flex: 1, minWidth: 120, type: 'number', editable: false, },
];

export default function EncashmentReportTable(props: {filter: IReportFilter}) {
    const { data, fetchDateBetween ,isLoading } = useGetMany<IEncashmentReport>(config.url.ENCASHMENT_REPORT);

    useEffect(() => {
        const filter: IFilter = { filterOperator:'BETWEEN', filterField: 'dateTime',
            filterValue: dateConvert(props.filter.startDateTime), filterSecondValue:dateConvert(props.filter.endDateTime) }
        fetchDateBetween(filter)
    }, [props.filter])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <DataGrid
                rows={data}
                columns={columns}
                loading={isLoading}
                hideFooter={true}
                autoHeight={true}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </Box>
    );
}