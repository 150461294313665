import {Box, Fab} from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';

import ServiceCardTable from "../components/serviceCardTable/ServiceCardTable";

export default function ServiceCardPage() {

    return (
        <Box sx={{ width: '100%' }}>
            <ServiceCardTable/>
        </Box>
    );
}
