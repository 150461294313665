import * as React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import {ChangeEvent, useState} from "react";
import {usePostOne} from "../../hooks/dataProvider/usePostOne";
import {config} from "../../data/Constants";
import {ISocialCard} from "./ISocialCard";
import AddSocialCardsDialog from './AddSocialCardsDialog';
import {useMessages} from "../../context/MessagesSnackbarContext";

interface IAddSocialCardDialogProps{
    isOpen:boolean
    onClosed(): void
    onCardAdded(): void
}




export default function AddSocialCardDialog(props: IAddSocialCardDialogProps) {
    const { setOkMessage } = useMessages();
    const { isOpen, onClosed, onCardAdded} = props
    const { isLoading, fetch } = usePostOne<ISocialCard>(config.url.SOCIAL_CARD)
    const[newCard, setNewCard] = useState<ISocialCard>({id: '', firstName: '', lastName:'', data:'', type:'', issueDate: new Date()});
    const [isIncorrectUidValue, setIncorrectUidValue] = useState<boolean>(false);
    const [uidHelpText, setUidHelpText] = useState<string>("");
    const [isIncorrectNameValue, setIncorrectNameValue] = useState<boolean>(false);
    const [nameHelpText, setNameHelpText] = useState<string>("");
    const [cards, setCards] = useState<ISocialCard[]>([]);
    const [isFromFileOpen, setFromFileOpen] = useState<boolean>(false);

    const handleClose = () => {
        setIncorrectUidValue(false)
        setUidHelpText('')
        setIncorrectNameValue(false)
        setNameHelpText('')
        setFromFileOpen(false)
        onClosed()
    }

    const handleSave = () => {
        if (validateUID(newCard.id) && validateName(newCard.firstName) && validateName(newCard.lastName)){
            fetch(newCard).then(value => {
                setOkMessage('Соціальна картка успішно добавлена.')
                onCardAdded()
                onClosed()
            })
        }
    }

    const pattern = /^[0-9A-F]{2}\ [0-9A-F]{2}\ [0-9A-F]{2}\ [0-9A-F]{2}$/i;
    const validateUID = (uid:string):boolean => {
        const result:boolean = pattern.test(uid);
        if (result){
            setIncorrectUidValue(false)
            setUidHelpText('')
            return true;
        } else {
            setIncorrectUidValue(true)
            setUidHelpText("некоректне значення (приклад '01 2F 54 A5')")
            return false;
        }
    };

    const validateName = (name:string):boolean => {
        if (name.length < 5){
            setIncorrectNameValue(true)
            setNameHelpText("Значення повинно бути більше ніж 5-ть символів." )
            return false
        } else if(name.length > 30) {
            setIncorrectNameValue(true)
            setNameHelpText("Значення повинно бути меньше ніж 30-ть символів.")
            return false
        } else {
            setIncorrectNameValue(false)
            setNameHelpText("")
            return true
        }
    };

    const loadFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
        try{
            const file = event.target?.files?.item(0)
            if (file !== null && file !== undefined){
                file.text().then(value => {
                    let rows: ISocialCard[] = []
                    const lines = value.split('\n')
                    lines.forEach(line => {
                        const items = line.split(';')
                        if (validateUID(items[0])){
                            const id = items[0]
                            const firstName = items[1] ? items[1] : ''
                            const lastName = items[2] ? items[2] : ''
                            const data = items[3] ? items[3] : ''
                            const type = items[4] ? items[4] : ''
                            const row: ISocialCard = { id: id, firstName: firstName, lastName: lastName, data: data, type: type, issueDate: new Date() }
                            rows.push(row)
                        }
                    })
                    setCards(rows)
                    setFromFileOpen(true)
                })
            }
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Нова соціальна картка</DialogTitle>
                <DialogContent>
                    <DialogContentText>Внесіть данні у відповідні поля</DialogContentText>
                    <TextField autoFocus margin="dense" id="socialCardUID" label="UID картки" type="text" fullWidth variant="standard"
                               inputProps={{size: 11, maxLength: 11, minLength: 6}}
                               error={ isIncorrectUidValue } helperText={ uidHelpText }
                               onChange={newValue => {
                                    let tmpCard = {...newCard}
                                    tmpCard.id = newValue.target.value
                                    setNewCard(tmpCard)}
                                }
                    />
                    <Box sx={{display:'flex', flexDirection:'row'}}>
                        <TextField autoFocus margin="dense" id="socialCardName" label="Ім'я" type="text" fullWidth variant="standard"
                                   sx={{marginRight: 2}}
                                   inputProps={{size: 20, maxLength: 30, minLength: 5}}
                                   error={ isIncorrectNameValue } helperText={ nameHelpText }
                                   onChange={newValue => {
                                       let tmpCard = {...newCard}
                                       tmpCard.firstName = newValue.target.value
                                       setNewCard(tmpCard)}
                                   }
                        />
                        <TextField autoFocus  margin="dense" id="socialCardName" label="Фамілія" type="text" fullWidth variant="standard"
                                   inputProps={{size: 20, maxLength: 30, minLength: 5}}
                                   error={ isIncorrectNameValue } helperText={ nameHelpText }
                                   onChange={newValue => {
                                       let tmpCard = {...newCard}
                                       tmpCard.lastName = newValue.target.value
                                       setNewCard(tmpCard)}
                                   }
                        />
                    </Box>
                    <TextField autoFocus margin="dense" id="socialCardUID" label="Додаткові данні" type="text" fullWidth variant="standard"
                               inputProps={{size: 30, maxLength: 30, minLength: 3}}
                               // error={ isIncorrectUidValue } helperText={ uidHelpText }
                               onChange={newValue => {
                                   let tmpCard = {...newCard}
                                   tmpCard.data = newValue.target.value
                                   setNewCard(tmpCard)}
                               }
                    />
                </DialogContent>
                <DialogActions>
                    <Box sx={{ width:'100%', marginLeft: 2, marginRight: 2, display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between' }} >
                        <Button variant="contained" component="label" sx={{ marginBottom: 1 }} >
                            Завантажити
                            <input hidden accept="text/csv" multiple type="file" onChange={ loadFileHandler } />
                        </Button>
                        <Box sx={{ display:'flex', flexDirection:'row', marginBottom: 1 }} >
                            <Button onClick={ handleClose } variant="outlined" sx={{marginRight: 1}} >Відміна</Button>
                            <Button onClick={ handleSave } variant="outlined" >Зберегти</Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
            <AddSocialCardsDialog cards={ cards } isOpen={ isFromFileOpen } onClosed={ handleClose } onCardAdded={ onCardAdded }/>
        </>
    );
}