import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid, GridCellParams,
    GridColDef, GridRenderCellParams, GridValueFormatterParams,
} from '@mui/x-data-grid';
import {useGetMany} from "../../hooks/dataProvider/useGetMany";
import {config} from "../../data/Constants";
import {useEffect} from "react";
import {dateFormat} from "../../data/dateFormat";
import {MessagesIconSet} from "./MessagesIconSet";
import {DeviceNumberButton} from "./DeviceNumberButton";

const twentyFourHours = 24 * 60 * 60 * 1000
const twelveHours = 12 * 60 * 60 * 1000
const halfHour = 30 * 60 *1000
const oneHour = 60 * 60 * 1000
const fiveMinutes = 5 * 60 * 1000
const twoMinutes = 2 * 60 * 1000


interface IConditionDto {
    id: number
    ip: string
    address: string
    city: string
    region: string
    deviceState: string
    coinAcceptorState: string
    nfcReaderState: string
    softwareVersion: string

    deviceTemperature: number
    cpuTemperature: number
    amountReboot: number
    lastCleaning: Date
    synchronizationDate: Date

    //for today
    acceptedCoins: number
    rejectedCoins: number
    coinsRejectionRate: number
    acceptedNfc: number
    rejectedNfc: number
    nfcRejectionRate: number
    sumForToday: number
    amountPaymentsForToday: number

    //finance
    paymentsWithoutCoins: number
    paymentsWithoutNfc: number
    lastCoin: Date
    lastNfc: Date

    //encashment
    amountCoinsInDevice: number
    sumCoinsInDevice: number
    lastEncashment: Date

    //messages
    messages: string
}

function formatToGrn(params: GridValueFormatterParams<number>) {
    if (params.value == null) {
        return ''
    }
    return params.value / 100
}

function RenderErrors(props: GridRenderCellParams<any, string>) {
    const { value } = props;
    return (
        <strong>
            <MessagesIconSet value={value} />
        </strong>
    );
}

function RenderDeviceNumber(props: GridRenderCellParams<any, number>) {
    const { value, row } = props;
    return (
        <DeviceNumberButton value={ value } ip={ row['ip'] } />

    );
}

const columns: GridColDef[] = [
    { field: 'id', headerName: '№', flex: 1, minWidth: 70,
        renderCell: RenderDeviceNumber },
    { field: 'ip', headerName: 'VPN ip-адрес', flex: 1, minWidth: 150, type: 'string', editable: false, },
    { field: 'region', headerName: 'Область', flex: 1, minWidth: 150, type: 'string', editable: false, },
    { field: 'city', headerName: 'Місто', flex: 1, minWidth: 130, type: 'string', editable: false, },
    { field: 'address', headerName: 'Адрес', flex: 1, minWidth: 230, type: 'string', editable: false, },
    { field: 'messages', headerName: 'Помилки', flex: 1, minWidth: 100,
        renderCell: RenderErrors
    },
    { field: 'amountReboot', headerName: 'Пере-завантаження', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'lastCleaning', headerName: 'Останне прибирання', flex: 1, minWidth: 160, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            return dateFormat(params.value)
        } },
    { field: 'synchronizationDate', headerName: 'Сінхронізація', flex: 1, minWidth: 160, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            return dateFormat(params.value)
        } },
    { field: 'softwareVersion', headerName: 'Версія ПЗ', flex: 1, minWidth: 110, type: 'string', editable: false, },
    { field: 'deviceState', headerName: 'Стан апарату', flex: 1, minWidth: 150, type: 'string', editable: false, },
    { field: 'coinAcceptorState', headerName: 'Стан монетоприймача', flex: 1, minWidth: 130, type: 'string', editable: false, },
    { field: 'nfcReaderState', headerName: 'Стан NFC_рідера', flex: 1, minWidth: 110, type: 'string', editable: false, },

    { field: 'deviceTemperature', headerName: 'Температура апарату', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'cpuTemperature', headerName: 'Температура процессора', flex: 1, minWidth: 110, type: 'number', editable: false, },

    { field: 'sumForToday', headerName: 'Сума', flex: 1, minWidth: 110, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            return formatToGrn(params)
        },},
    { field: 'amountPaymentsForToday', headerName: 'Кількість платежів', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'acceptedCoins', headerName: 'Прийнятих монет', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'rejectedCoins', headerName: 'Відхилених монет', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'coinsRejectionRate', headerName: 'Процент відмов монет', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'acceptedNfc', headerName: 'Прийнятих NFC', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'rejectedNfc', headerName: 'Відхилених NFC', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'nfcRejectionRate', headerName: 'Процент відмов NFC', flex: 1, minWidth: 110, type: 'number', editable: false, },

    { field: 'paymentsWithoutCoins', headerName: 'Платежів без монет', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'paymentsWithoutNfc', headerName: 'Платежів без NFC', flex: 1, minWidth: 110, type: 'number', editable: false, },
    { field: 'lastCoin', headerName: 'Останній платіж монетою', flex: 1, minWidth: 160, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            return dateFormat(params.value)
        } },
    { field: 'lastNfc', headerName: 'Останній платіж NFC', flex: 1, minWidth: 160, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            return dateFormat(params.value)
        } },

    { field: 'amountCoinsInDevice', headerName: 'Кількість монет в апараті', flex: 1, minWidth: 140, type: 'number', editable: false, },
    { field: 'sumCoinsInDevice', headerName: 'Сума монет в апараті', flex: 1, minWidth: 140, type: 'number', editable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) => {
            return formatToGrn(params)
        },},
    { field: 'lastEncashment', headerName: 'Остання інкасація', flex: 1, minWidth: 160, type: 'dateTime', editable: false,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
            return dateFormat(params.value)
        } },

];

const cellStyling = (params: GridCellParams<any, any, number>) => {
    if (params.field === 'lastCleaning') {
        const msBetweenDates = Date.now().valueOf() - new Date(params.value).valueOf();
        return greatThanNumberStyle(msBetweenDates, twelveHours, twentyFourHours)
    } else if (params.field === 'deviceTemperature') {
        return lessThanNumberStyle(params.value, 4, 2)
    } else if (params.field === 'cpuTemperature') {
        return greatThanNumberStyle(params.value, 70, 80)
    } else if (params.field === 'amountReboot'){
        return greatThanNumberStyle(params.value, 3, 5)
    } else if (params.field === 'paymentsWithoutCoins'){
        return greatThanNumberStyle(params.value, 8, 15)
    } else if (params.field === 'paymentsWithoutNfc'){
        return greatThanNumberStyle(params.value, 8, 15)
    } else if (params.field === 'coinsRejectionRate'){
        if ((params.row['acceptedCoins'] + params.row['rejectedCoins']) > 6){
            return greatThanNumberStyle(params.value, 30, 50)
        }
    } else if (params.field === 'nfcRejectionRate'){
        if ((params.row['acceptedNfc'] + params.row['rejectedNfc']) > 6){
            return greatThanNumberStyle(params.value, 30, 50)
        }
    } else if (params.field === 'lastCoin'){
        const msBetweenDates = Date.now().valueOf() - new Date(params.value).valueOf();
        return greatThanNumberStyle(msBetweenDates, twelveHours, twentyFourHours)
    } else if (params.field === 'lastNfc'){
        const msBetweenDates = Date.now().valueOf() - new Date(params.value).valueOf();
        return greatThanNumberStyle(msBetweenDates, twelveHours, twentyFourHours)
    } else if (params.field === 'synchronizationDate'){
        const msBetweenDates = Date.now().valueOf() - new Date(params.value).valueOf();
        return greatThanNumberStyle(msBetweenDates, halfHour, oneHour)
    } else if (params.field === 'deviceState'){
        return deviceStateStyle(params.value)
    } else if (params.field === 'coinAcceptorState'){
        return equipmentStateStyle(params.value)
    } else if (params.field === 'nfcReaderState'){
        return equipmentStateStyle(params.value)
    }
    return '';
}

const greatThanNumberStyle = (value: number, firstActuationValue: number, greaterActuationValue: number):string => {
    if ( value > greaterActuationValue ){
        return 'hot'
    }else if ( value > firstActuationValue ){
        return 'cold'
    }
    return '';
}

const lessThanNumberStyle = (value: number, firstActuationValue: number, lessActuationValue: number):string => {
    if ( value < lessActuationValue ){
        return 'hot'
    }else if ( value < firstActuationValue ){
        return 'cold'
    }
    return '';
}

const deviceStateStyle = (value: string):string => {
    switch (value) {
        case 'INITIALIZATION':
        case 'CHECK_STATE':
            return 'cold'
        case 'NOT_WORK':
        case 'DETECTION_ALARM':
        case 'USE_ALARM':
        case 'OPEN_DOOR_ALARM':
        case 'CLIENT_INSIDE_ALARM':
        case 'ERROR_ALARM':
        case 'ALARM':
            return 'hot'
    }
    return '';
}

const equipmentStateStyle = (value: string):string => {
    switch (value) {
        case 'DISABLED':
            return 'cold'
        case 'STOP':
        case 'SEARCHING':
        case 'NOT_FOUND':
        case 'ERROR':
            return 'hot'
    }
    return '';
}

export default function MonitoringTable() {
    const { data, fetchAll ,isLoading } = useGetMany<IConditionDto>(config.url.MONITORING);

    useEffect(() => {
        let interval = setInterval(() => {
            fetchAll()
        }, twoMinutes);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        fetchAll()
    }, [])

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <DataGrid sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1.5
                    },
                    '& .cold': {
                        backgroundColor: '#b9d5ff91',
                        color: '#1a3e72',
                    },
                    '& .hot': {
                        backgroundColor: '#ff943975',
                        color: '#1a3e72',
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: '1px solid #e0e0e0',
                    },
                    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                        borderBottom: '1px solid  #e0e0e0',
                    },

                }}
                      rows={data}
                      columnHeaderHeight={80}
                      columns={columns}
                      density="compact"
                      loading={isLoading}
                      hideFooter={true}
                      hideFooterSelectedRowCount={true}
                      hideFooterPagination={true}
                      autoHeight={true}
                      getCellClassName={ cellStyling }
                      initialState={{
                          columns: {
                              columnVisibilityModel: {
                                  // Hide columns ip, the other columns will remain visible
                                  ip: false,
                              },
                          },
                      }}
            />
        </Box>
    );
}