import React from 'react';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { navigationItems } from './navigationItem';
import {Stack, Typography, Toolbar, ListItemText, ListItemIcon, ListItemButton, ListItem, List, IconButton, Drawer, Divider, Box, AppBar} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import {useAuth} from "../context/AuthContext";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


interface Props {
    open:boolean
    openHandler():void
    drawerWidth:number
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;

}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



export default function ResponsiveDrawer(props: Props) {


    const { window, drawerWidth, open, openHandler } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const {updateLogin, userRole, updateRole} = useAuth();

    const handleMobileDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem("wc-token");
        updateLogin(false)
        updateRole("ROLE_GUEST")
    };

    const drawer = (
        <div>
            <Divider />
            <List>
                <ListItem key={0} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.items[0].path} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                            < HomeIcon/>
                        </ListItemIcon>
                        { open && <ListItemText primary={navigationItems.items[0].name} /> }
                    </ListItemButton>
                </ListItem>
                <ListItem key={1} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.items[1].path} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                            < AssessmentIcon/>
                        </ListItemIcon>
                        { open && <ListItemText primary={navigationItems.items[1].name} /> }
                    </ListItemButton>
                </ListItem>
                <ListItem key={2} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.items[2].path} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                            < SupportAgentIcon/>
                        </ListItemIcon>
                        { open && <ListItemText primary={navigationItems.items[2].name} /> }
                    </ListItemButton>
                </ListItem>
                <ListItem key={3} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.items[3].path} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                            < Diversity3Icon/>
                        </ListItemIcon>
                        { open && <ListItemText primary={navigationItems.items[3].name} /> }
                    </ListItemButton>
                </ListItem>
                <ListItem key={4} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.items[4].path} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                            < SettingsIcon/>
                        </ListItemIcon>
                        { open && <ListItemText primary={navigationItems.items[4].name} /> }
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key={5} onClick={ handleLogout } disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.items[5].path} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                            < LogoutIcon/>
                        </ListItemIcon>
                        { open && <ListItemText primary={navigationItems.items[5].name} /> }
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ display: { xs: 'block', sm: 'none' }, width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` }, }} >
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" edge="start"
                                onClick={handleMobileDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }} >
                        <MenuIcon />
                    </IconButton>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={"100%"} >
                        {/*    Элементы справа в AppTaskbar */}
                    </Stack>

                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders" >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* мобильная версия*/}
                <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleMobileDrawerToggle}
                        ModalProps={{ keepMounted: true, }} // Better open performance on mobile.
                        sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, }} >
                    <DrawerHeader>
                        <IconButton onClick={ openHandler }>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    {drawer}
                </Drawer>
                {/* десктопная версия*/}
                <Drawer variant="permanent" open={open} sx={{ display: { xs: 'none', sm: 'block' } }} >
                    <DrawerHeader sx={{ width: drawerWidth }}>
                        <IconButton onClick={ openHandler }>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    {drawer}

                </Drawer>
            </Box>
        </Box>
    );
}

